// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-boardmaker-license-js": () => import("./../src/pages/boardmakerLicense.js" /* webpackChunkName: "component---src-pages-boardmaker-license-js" */),
  "component---src-pages-community-index-js": () => import("./../src/pages/community/index.js" /* webpackChunkName: "component---src-pages-community-index-js" */),
  "component---src-pages-community-start-js": () => import("./../src/pages/community/start.js" /* webpackChunkName: "component---src-pages-community-start-js" */),
  "component---src-pages-compare-js": () => import("./../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-downloads-js": () => import("./../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-error-js": () => import("./../src/pages/Error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-index-js": () => import("./../src/pages/license/index.js" /* webpackChunkName: "component---src-pages-license-index-js" */),
  "component---src-pages-license-request-js": () => import("./../src/pages/licenseRequest.js" /* webpackChunkName: "component---src-pages-license-request-js" */),
  "component---src-pages-license-thankyou-js": () => import("./../src/pages/license/thankyou.js" /* webpackChunkName: "component---src-pages-license-thankyou-js" */),
  "component---src-pages-license-trial-start-js": () => import("./../src/pages/license/trial/start.js" /* webpackChunkName: "component---src-pages-license-trial-start-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-myplan-index-js": () => import("./../src/pages/myplan/index.js" /* webpackChunkName: "component---src-pages-myplan-index-js" */),
  "component---src-pages-myplan-modify-index-js": () => import("./../src/pages/myplan/modify/index.js" /* webpackChunkName: "component---src-pages-myplan-modify-index-js" */),
  "component---src-pages-myplan-modify-review-js": () => import("./../src/pages/myplan/modify/review.js" /* webpackChunkName: "component---src-pages-myplan-modify-review-js" */),
  "component---src-pages-myplan-modify-thankyou-js": () => import("./../src/pages/myplan/modify/thankyou.js" /* webpackChunkName: "component---src-pages-myplan-modify-thankyou-js" */),
  "component---src-pages-myplan-paymentdetails-js": () => import("./../src/pages/myplan/paymentdetails.js" /* webpackChunkName: "component---src-pages-myplan-paymentdetails-js" */),
  "component---src-pages-organization-js": () => import("./../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-partner-js": () => import("./../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-paymentdetails-js": () => import("./../src/pages/paymentdetails.js" /* webpackChunkName: "component---src-pages-paymentdetails-js" */),
  "component---src-pages-personal-js": () => import("./../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-professional-js": () => import("./../src/pages/professional.js" /* webpackChunkName: "component---src-pages-professional-js" */),
  "component---src-pages-redirect-js": () => import("./../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-revieworder-js": () => import("./../src/pages/revieworder.js" /* webpackChunkName: "component---src-pages-revieworder-js" */),
  "component---src-pages-taxexempt-start-js": () => import("./../src/pages/taxexempt/start.js" /* webpackChunkName: "component---src-pages-taxexempt-start-js" */),
  "component---src-pages-taxexempt-thankyou-js": () => import("./../src/pages/taxexempt/thankyou.js" /* webpackChunkName: "component---src-pages-taxexempt-thankyou-js" */),
  "component---src-pages-thankyou-js": () => import("./../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-trial-license-js": () => import("./../src/pages/trialLicense.js" /* webpackChunkName: "component---src-pages-trial-license-js" */),
  "component---src-pages-trial-start-js": () => import("./../src/pages/trial/start.js" /* webpackChunkName: "component---src-pages-trial-start-js" */)
}

