import React from 'react';
import PageHeader from '@ui/PageHeader';
import { useTranslation, Trans } from 'react-i18next';

export default function ErrorContent() {
  const { t } = useTranslation();
  //* render children
  return (
    <div>
      <PageHeader className="pb-2">{t('errors.sorry')}</PageHeader>
      <p className="pb-2">
        {/* prettier-ignore */}
        <Trans i18nKey="errors.somethingWentWrong">
          Something went wrong on our end. We've logged this error and urge you
          to
          <a href={process.env.CONTACT_US}>
            {{ link: 'contact us' }}
          </a>
          if you encounter this problem again.
        </Trans>
      </p>
      <hr className="pb-2" />
      <p className="pb-2">
        {/* prettier-ignore */}
        <Trans i18nKey="errors.tryGoingBack">
          Try going back or
          <a href="/">{{ link: 'return to the homepage.' }}</a>
        </Trans>
      </p>
    </div>
  );
}
