import React, { useEffect, useState } from 'react';
import axios from 'axios';
import i18n from '../../i18n/i18n';
import { useLocale } from '@util/providers/Locale';
import { sortList } from '@util/functions';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export default function LanguageSelect() {
  const { selectedLanguage, setSelectedLanguage } = useLocale();
  const [dropdownLanguages, setDropdownLanguages] = useState();

  useEffect(() => {
    let url = process.env.INTERNAL_SERVICE_URL + 'admin/locales';
    axios(url, {
      method: 'GET',
    })
      .then(function(response) {
        if (response.status === 200) {
          return response;
        }
        throw Error(response.statusText);
      })
      .then(function(response) {
        setDropdownLanguages(sortList(response.data));
      })
      .catch(err => {
        console.error(err.message);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <UncontrolledDropdown>
      {dropdownLanguages && (
        <>
          <DropdownToggle
            caret
            color="outline-opaque-secondary"
            className="btn btn-block"
          >
            {selectedLanguage ? <>{selectedLanguage}</> : <>{'English (US)'}</>}
          </DropdownToggle>

          <DropdownMenu style={{ overflowY: 'scroll', maxHeight: '40rem' }}>
            {dropdownLanguages.map(language => (
              <DropdownItem
                id={language.code}
                key={language.code}
                autoFocus={
                  language.code.substring(0, 2) === i18n.language && true
                }
                onClick={() => {
                  setSelectedLanguage(language.name);
                  i18n.changeLanguage(language.code.substring(0, 2));
                }}
              >
                {language.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </>
      )}
    </UncontrolledDropdown>
  );
}
