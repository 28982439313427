import React from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import styled from 'styled-components';
import useAxios from 'axios-hooks';

import AlertError from '@util/Errors';
import Loading from '@ui/Loading';

export default function LegalLinkContent({ markdown = true, url }) {
  const [
    { data, loading, error },
    // refetch
  ] = useAxios(url);

  if (loading) return <Loading />;
  if (error) return <AlertError>{error.message}</AlertError>;

  if (!markdown)
    return <ReactMarkdownWithHtml source={data} escapeHtml={false} />;

  return <StyledReactMarkdown source={data} />;
}

const StyledReactMarkdown = styled(ReactMarkdown)`
  li {
    margin: 2em 0 1.5em 0;
    & li {
      margin: 1em 0;
      font-size: 0.9rem;
      & li {
        font-size: 0.8rem;
      }
    }
    & > p {
      margin: 0;
      padding: 0;
      display: inline;
    }
    & > p + p {
      margin: 0.5rem 0;
      display: block;
      text-indent: 0;
    }
  }

  ol {
    counter-reset: item;
    padding: 0;
    li > ol {
      //padding-left:2rem;
    }
    li {
      display: block;
      padding-left: 2rem;
      text-indent: -2rem;
    }
    li:before {
      margin-left: 0.5rem;
      content: counters(item, '.') '. ';
      counter-increment: item;
    }
  }
`;
