import React from 'react';

import { PlanProvider, usePlan } from './Plan';
import { BillingPaymentProvider, useBillingPayment } from './BillingPayment';
import { InvoiceProvider, useInvoice } from './Invoice';
import { LicenseProvider } from './License';

export default function({ children }) {
  return (
    <PlanProvider>
      <LicenseProvider>
        <BillingPaymentProvider>
          <InvoiceProvider>{children}</InvoiceProvider>
        </BillingPaymentProvider>
      </LicenseProvider>
    </PlanProvider>
  );
}

export function useResetSession() {
  const { resetSelectedPlan, resetSelectedRate } = usePlan();
  const { resetBillingDetails, resetPaymentDetails } = useBillingPayment();
  const {
    resetInvoiceNumber,
    resetInvoiceId,
    resetInvoicePreview,
  } = useInvoice();

  return function doReset() {
    resetSelectedPlan();
    resetSelectedRate();
    resetBillingDetails();
    resetPaymentDetails();
    resetInvoiceNumber();
    resetInvoiceId();
    resetInvoicePreview();
  };
}
