import React, { createContext, useState, useContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { CancelToken } from 'axios';

import { useLocale } from '@util/providers/Locale';

import { useStateWithSessionStorage } from '../hooks/useStateWithSessionStorage';
import { getProductCatalog } from '@util/Plans/PlanFunctions';

const cancelSource = CancelToken.source();

const PlanContext = createContext({});

const defaultValues = {
  termIsYearly: true,
  trialLength: 30,
  plans: null,
  selectedPlan: null,
  selectedRate: null,
};

function PlanProvider({ children }) {
  const [termIsYearly, setTermIsYearly] = useState(defaultValues.termIsYearly);
  const [plans, setPlans] = useStateWithSessionStorage(
    'plans',
    defaultValues.plans
  );
  const [trialLength, setTrialLength] = useStateWithSessionStorage(
    'trialLength',
    defaultValues.trialLength
  );
  const [selectedPlan, setSelectedPlan] = useStateWithSessionStorage(
    'selectedPlan',
    defaultValues.selectedPlan
  );

  const [selectedRate, setSelectedRate] = useStateWithSessionStorage(
    'selectedRate',
    defaultValues.selectedRate
  );

  const {
    selectedCurrency,
    locale,
    selectedLanguage,
    setSelectedCurrency,
  } = useLocale();

  const [contextValue, setContextValue] = useState({
    plans,
    setPlans,
    selectedPlan,
    setSelectedPlan,
    selectedRate,
    setSelectedRate,
    setSelectedRateByPlan: plan => {
      if (plan.staticRatePlan) {
        setSelectedRate(plan.staticRatePlan);
      } else {
        setSelectedRate(prevRate => {
          if (plan && prevRate) {
            //* default to monthly rate plan if the previous rate is not a trial or yearly rate
            let rateType = 'isYearly';
            if (prevRate.isTrial) rateType = 'isTrial';
            if (prevRate.isYearly === false) rateType = 'isMonthly';

            return plan.ratePlans.find(rp => rp[rateType]);
          }

          return null;
        });
      }
    },
    termIsYearly,
    setTermIsYearly,
    resetSelectedPlan: () => setSelectedPlan(defaultValues.selectedPlan),
    resetSelectedRate: () => setSelectedRate(defaultValues.selectedRate),
  });

  const [
    // eslint-disable-next-line
    { data: catalogData, loading: loadingCatalog, error: catalogError },
    getCatalog,
  ] = useAxios(
    selectedCurrency
      ? `${
          process.env.SERVICE_URL
        }subscriptions/catalog/${selectedCurrency.toLowerCase()}`
      : '',
    {
      manual: true,
      cancelToken: cancelSource.token,
    }
  );

  useEffect(() => {
    setContextValue(contextValue => ({
      ...contextValue,
      selectedPlan,
      selectedRate,
      trialLength,
      termIsYearly,
    }));
  }, [selectedPlan, selectedRate, termIsYearly, trialLength]);

  //* fetch plans when currency changes
  useEffect(() => {
    if (
      locale?.localeDetails?.defaultToSubscription === true &&
      locale?.localeDetails?.isoCode !== 'CA'
    )
      setSelectedCurrency('USD');
    if (selectedCurrency === 'USD' || selectedCurrency === 'CAD') getCatalog();

    // eslint-disable-next-line
  }, [locale, selectedLanguage, selectedCurrency]);

  //* set catalog when new data is received from the service
  useEffect(() => {
    if (catalogData) {
      let productCatalog = getProductCatalog(catalogData, selectedCurrency);
      setPlans(productCatalog());
      setTrialLength(catalogData.trialDays);
    }
    // eslint-disable-next-line
  }, [catalogData]);

  //* throw error if catalog fails to load
  useEffect(() => {
    if (catalogError) {
      throw new Error('Error retrieving plan catalog.');
    }
    // eslint-disable-next-line
  }, [catalogError]);

  //* update context
  useEffect(() => {
    //* set aligned plan and rate
    let newPlan, newRate;
    if (plans && selectedPlan) {
      newPlan = plans.find(p => p.key === selectedPlan.key);
      newPlan && setSelectedPlan(newPlan);
    }
    if (plans && selectedRate && newPlan && !newPlan.staticRatePlan) {
      newRate = newPlan.ratePlans.find(rp => rp.key === selectedRate.key);
      newRate && setSelectedRate(newRate);
    }
    setContextValue(contextValue => ({
      ...contextValue,
      plans,
    }));
    // eslint-disable-next-line
  }, [plans]);

  return (
    <PlanContext.Provider value={contextValue}>{children}</PlanContext.Provider>
  );
}

function usePlan() {
  const context = useContext(PlanContext);
  if (context === undefined) {
    throw new Error('usePlan must be used within a PlanProvider');
  }

  return context;
}

export { PlanProvider, usePlan };
