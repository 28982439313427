import i18n from '../i18n/i18n';

export const staticPlanDetailsWithoutOrganization = () => [
  staticPlanDetailsCommunity(),
  staticPlanDetailsPersonal(),
  staticPlanDetailsProfessional(),
];

export const testStaticPlanDetails = () => [
  staticPlanDetailsCommunity(),
  staticPlanDetailsPersonal(),
  staticPlanDetailsProfessional(),
];

export const staticPlanDetailsCommunity = () => {
  return {
    id: '0',
    active: true,
    key: 'community',
    name: i18n.t('plans:community.name'),
    shortDescription: i18n.t('plans:community.shortDescription'),
    actionText: 'action.joinToday',
    features: [
      i18n.t('plans:community.features.0'),
      i18n.t('plans:community.features.1'),
      i18n.t('plans:community.features.2'),
    ],
    hideRatePlanOptions: true,
    staticRatePlan: {
      key: 'community-all',
      free: true,
      price: i18n.t('plans:community.price'),
      termName: i18n.t('plans:community.termName'),
      disclaimerKey: 'planCard.noCCRequired',
    },
    ratePlans: [
      {
        id: 'community-monthly',
        termName: i18n.t('plans:community.termName'),
        free: true,
        price: 'Free',
        baseRate: true,
        isMonthly: true,
        isYearly: false,
      },
      {
        id: 'community-yearly',
        termName: i18n.t('plans:community.termName'),
        free: true,
        price: 'Free',
        isYearly: true,
      },
    ],
    planSelectRoutes: {
      loggedOut: '/account',
      loggedIn: '/community/start',
      quickSelect: {
        loggedOut: null,
        loggedIn: '/community/start',
      },
    },
    warnOnAbortSubscription: true,
  };
};

export const staticPlanDetailsPersonal = selectedCurrency => {
  const catalogIds = {
    USD: [
      process.env.ZUORA_PERSONAL_ID,
      process.env.ZUORA_PERSONAL_RATE_1MONTH_ID,
      process.env.ZUORA_PERSONAL_RATE_1YEAR_ID,
      process.env.ZUORA_PERSONAL_RATE_2YEAR_ID,
      process.env.ZUORA_PERSONAL_RATE_3YEAR_ID,
    ],
    CAD: [
      process.env.ZUORA_PERSONAL_ID_CAD,
      process.env.ZUORA_PERSONAL_RATE_1MONTH_ID_CAD,
      process.env.ZUORA_PERSONAL_RATE_1YEAR_ID_CAD,
      process.env.ZUORA_PERSONAL_RATE_2YEAR_ID_CAD,
      process.env.ZUORA_PERSONAL_RATE_3YEAR_ID_CAD,
    ],
  };

  return {
    id: catalogIds[selectedCurrency.toUpperCase()][0],
    active: true,
    key: 'personal',
    name: i18n.t('plans:personal.name'),
    displayName: i18n.t('plans:personal.name'),
    shortDescription: i18n.t('plans:personal.shortDescription'),
    actionText: 'action.buyNow',
    hasTrial: true,
    features: [
      i18n.t('plans:personal.features.0'),
      i18n.t('plans:personal.features.1'),
      i18n.t('plans:personal.features.2'),
    ],
    ratePlans: [
      {
        id: 'personal-trial',
        active: true,
        key: 'personal-trial',
        forceMerge: true,
        isTrial: true,
      },
      {
        id: catalogIds[selectedCurrency.toUpperCase()][1],
        active: true,
        key: 'personal-monthly',
        baseRate: true,
        isMonthly: true,
        isYearly: false,
        disclaimerKey: 'planCard.cCOnly',
      },
      {
        id: catalogIds[selectedCurrency.toUpperCase()][2],
        active: true,
        key: 'personal-yearly',
        isYearly: true,
        disclaimerKey: 'planCard.cCOrPO',
      },
      // {
      //   // id: catalogIds[selectedCurrency.toUpperCase()][3],
      //   id: '2c92c0f9425d9f42014276c7f6f23ba4',
      //   key: 'personal-2',
      //   isDiscontinued: true,
      // },
      // {
      //   id: catalogIds[selectedCurrency.toUpperCase()][4],
      //   key: 'personal-3',
      //   isDiscontinued: true,
      // },
    ],
    planSelectRoutes: {
      loggedOut: '/account',
      loggedIn: '/paymentdetails',
      trial: {
        loggedOut: '/account',
        loggedIn: '/trial/start',
        quickSelect: {
          loggedOut: null,
          loggedIn: '/trial/start',
        },
      },
    },
  };
};

export const staticPlanDetailsProfessional = selectedCurrency => {
  const catalogIds = {
    USD: [
      process.env.ZUORA_PROFESSIONAL_ID,
      process.env.ZUORA_PROFESSIONAL_RATE_1MONTH_ID,
      process.env.ZUORA_PROFESSIONAL_RATE_1YEAR_ID,
      process.env.ZUORA_PROFESSIONAL_RATE_2YEAR_ID,
      process.env.ZUORA_PROFESSIONAL_RATE_3YEAR_ID,
    ],
    CAD: [
      process.env.ZUORA_PROFESSIONAL_ID_CAD,
      process.env.ZUORA_PROFESSIONAL_RATE_1MONTH_ID_CAD,
      process.env.ZUORA_PROFESSIONAL_RATE_1YEAR_ID_CAD,
      process.env.ZUORA_PROFESSIONAL_RATE_2YEAR_ID_CAD,
      process.env.ZUORA_PROFESSIONAL_RATE_3YEAR_ID_CAD,
    ],
  };
  return {
    id: catalogIds[selectedCurrency.toUpperCase()][0],
    active: true,
    key: 'professional',
    name: i18n.t('plans:professional.name'),
    displayName: i18n.t('plans:professional.name'),
    shortDescription: i18n.t('plans:professional.shortDescription'),
    actionText: 'action.buyNow',
    hasTrial: true,
    features: [
      i18n.t('plans:professional.features.0'),
      i18n.t('plans:professional.features.1'),
      i18n.t('plans:professional.features.2'),
    ],
    ratePlans: [
      {
        id: 'professional-trial',
        active: true,
        key: 'professional-trial',
        forceMerge: true,
        isTrial: true,
      },
      {
        id: catalogIds[selectedCurrency.toUpperCase()][1],
        active: true,
        key: 'professional-monthly',
        baseRate: true,
        isMonthly: true,
        isYearly: false,
        disclaimerKey: 'planCard.cCOnly',
      },
      {
        id: catalogIds[selectedCurrency.toUpperCase()][2],
        active: true,
        key: 'professional-yearly',
        isYearly: true,
        disclaimerKey: 'planCard.cCOrPO',
      },
    ],
    planSelectRoutes: {
      loggedOut: '/account',
      loggedIn: '/paymentdetails',
      trial: {
        loggedOut: '/account',
        loggedIn: '/trial/start',
        quickSelect: {
          loggedOut: null,
          loggedIn: '/trial/start',
        },
      },
    },
  };
};

export const staticPlanDetailsOrganization = () => ({
  id: '3',
  active: true,
  key: 'organization',
  name: i18n.t('plans:organization.name'),
  shortDescription: i18n.t('plans:organization.shortDescription'),
  actionText: 'action.requestAQuote',
  staticRatePlan: {
    key: 'organization-all',
    requiresQuote: true,
    price: i18n.t('plans:organization.price'),
    termName: i18n.t('plans:organization.termName'),
    disclaimerKey: 'planCard.cCOrPO',
  },
  features: [
    i18n.t('plans:organization.features.0'),
    i18n.t('plans:organization.features.1'),
    i18n.t('plans:organization.features.2'),
  ],
  hideRatePlanOptions: true,
  excludeFromPlanSummaryCard: true,
  planSelectRoutes: {
    loggedOut: process.env.ORG_REQUEST_LINK,
    loggedIn: process.env.ORG_REQUEST_LINK,
    quickSelect: {
      loggedOut: process.env.ORG_REQUEST_LINK,
      loggedIn: process.env.ORG_REQUEST_LINK,
    },
  },
});

export const staticPlanDetailsLicenses = () => {
  return [
    {
      id: '4',
      active: true,
      key: 'trialLicense',
      name: i18n.t('plans:trialLicense.name'),
      shortDescription: i18n.t('plans:trialLicense.shortDescription'),
      action: {
        text: 'action.startTrial',
        route: '/license/trial/start',
      },
      hideRatePlanOptions: true,
      note: i18n.t('plans:trialLicense.note'),
      staticRatePlan: {
        key: 'trialLicense-all',
        free: true,
        price: i18n.t('plans:trialLicense.price'),
        termName: i18n.t('plans:trialLicense.termName'),
        disclaimerKey: i18n.t('plans:trialLicense.footerNote'),
      },
      ratePlans: [
        {
          id: 'trialLicense-monthly',
          termName: i18n.t('plans:trialLicense.termName'),
          free: true,
          price: 'Free',
          baseRate: true,
          isMonthly: false,
          isYearly: false,
        },
      ],
      planSelectRoutes: {
        loggedOut: '/download',
        loggedIn: '/download',
        quickSelect: {
          loggedOut: '/download',
          loggedIn: '/download',
        },
      },
      warnOnAbortSubscription: false,
    },
    {
      id: '5',
      active: true,
      key: 'boardmakerLicense',
      name: i18n.t('plans:boardmakerLicense.name'),
      shortDescription: i18n.t('plans:boardmakerLicense.shortDescription'),
      action: {
        text: 'action.buyNow',
        shopify: true,
      },
      hideRatePlanOptions: true,
      note: i18n.t('plans:boardmakerLicense.note'),
      footerNote: i18n.t('plans:boardmakerLicense.footerNote'),
      hasStaticRatePlanPrice: true,
      staticRatePlan: {
        key: 'boardmakerLicense-all',
        free: false,
        price: i18n.t('plans:boardmakerLicense.price'),
        termName: i18n.t('plans:boardmakerLicense.termName'),
        disclaimerKey: i18n.t('plans:boardmakerLicense.footerNote'),
      },
      ratePlans: [
        {
          id: 'boardmakerLicense-monthly',
          termName: i18n.t('plans:boardmakerLicense.termName'),
          free: false,
          price: 'Free',
          baseRate: true,
          isMonthly: false,
          isYearly: false,
        },
      ],
      planSelectRoutes: {
        loggedOut: '/license',
        loggedIn: '/license',
        quickSelect: {
          loggedOut: null,
          loggedIn: '/license',
        },
      },
      warnOnAbortSubscription: false,
    },
    {
      id: '6',
      active: true,
      key: 'licenseRequest',
      name: i18n.t('plans:licenseRequest.name'),
      shortDescription: i18n.t('plans:licenseRequest.shortDescription'),
      action: {
        text: 'action.requestAQuote',
        route: process.env.ORG_REQUEST_LINK,
      },
      staticRatePlan: {
        key: 'licenseRequest-all',
        requiresQuote: true,
        price: i18n.t('plans:licenseRequest.price'),
        termName: i18n.t('plans:licenseRequest.termName'),
        disclaimerKey: i18n.t('plans:licenseRequest.footerNote'),
      },
      hideRatePlanOptions: true,
      excludeFromPlanSummaryCard: true,
      planSelectRoutes: {
        loggedOut: process.env.ORG_REQUEST_LINK,
        loggedIn: process.env.ORG_REQUEST_LINK,
        quickSelect: {
          loggedOut: process.env.ORG_REQUEST_LINK,
          loggedIn: process.env.ORG_REQUEST_LINK,
        },
      },
    },
  ];
};

export const learnMoreDetails = {
  community: {
    summary: i18n.t('planDetails:community.summary'),
    topReasons: {
      header: i18n.t('planDetails:community.topReasons.header'),
      points: [
        i18n.t('planDetails:community.topReasons.points.1'),
        i18n.t('planDetails:community.topReasons.points.2'),
        i18n.t('planDetails:community.topReasons.points.3'),
        i18n.t('planDetails:community.topReasons.points.4'),
      ],
    },
    summaryEnd: i18n.t('planDetails:community.summaryEnd'),
    features: [
      {
        featureTitle: i18n.t('planDetails:community.features.0.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:community.features.0.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t('planDetails:community.features.1.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:community.features.1.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t('planDetails:community.features.2.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:community.features.2.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t('planDetails:community.features.3.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:community.features.3.featureDescription'
        ),
      },
    ],
    advantages: [],
    requirements: {
      operatingSystem: [
        [
          i18n.t('planDetails:community.requirements.operatingSystem.0.0'),
          i18n.t('planDetails:community.requirements.operatingSystem.0.1'),
          i18n.t('planDetails:community.requirements.operatingSystem.0.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.operatingSystem.1.0'),
          i18n.t('planDetails:community.requirements.operatingSystem.1.1'),
          i18n.t('planDetails:community.requirements.operatingSystem.1.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.operatingSystem.2.0'),
          i18n.t('planDetails:community.requirements.operatingSystem.2.1'),
          i18n.t('planDetails:community.requirements.operatingSystem.2.2'),
        ],
      ],
      browser: [
        [
          i18n.t('planDetails:community.requirements.browser.0.0'),
          i18n.t('planDetails:community.requirements.browser.0.1'),
          i18n.t('planDetails:community.requirements.browser.0.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.browser.1.0'),
          i18n.t('planDetails:community.requirements.browser.1.1'),
          i18n.t('planDetails:community.requirements.browser.1.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.browser.2.0'),
          i18n.t('planDetails:community.requirements.browser.2.1'),
          i18n.t('planDetails:community.requirements.browser.2.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.browser.3.0'),
          i18n.t('planDetails:community.requirements.browser.3.1'),
          i18n.t('planDetails:community.requirements.browser.3.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.browser.4.0'),
          i18n.t('planDetails:community.requirements.browser.4.1'),
          i18n.t('planDetails:community.requirements.browser.4.2'),
        ],
      ],
    },
  },
  personal: {
    summary: i18n.t('planDetails:personal.summary'),
    topReasons: {
      header: i18n.t('planDetails:personal.topReasons.header'),
      points: [
        i18n.t('planDetails:personal.topReasons.points.0'),
        i18n.t('planDetails:personal.topReasons.points.1'),
        i18n.t('planDetails:personal.topReasons.points.2'),
        i18n.t('planDetails:personal.topReasons.points.3'),
      ],
    },
    summaryEnd: i18n.t('planDetails:personal.summaryEnd'),
    features: [
      {
        featureTitle: i18n.t('planDetails:personal.features.0.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:personal.features.0.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t('planDetails:personal.features.1.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:personal.features.1.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t('planDetails:personal.features.2.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:personal.features.2.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t('planDetails:personal.features.3.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:personal.features.3.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t('planDetails:personal.features.4.featureTitle'),
        featureDescription: i18n.t(
          'planDetails:personal.features.4.featureDescription'
        ),
      },
    ],
    advantages: [],
    requirements: {
      operatingSystem: [
        [
          i18n.t('planDetails:personal.requirements.operatingSystem.0.0'),
          i18n.t('planDetails:personal.requirements.operatingSystem.0.1'),
          i18n.t('planDetails:personal.requirements.operatingSystem.0.2'),
        ],
        [
          i18n.t('planDetails:personal.requirements.operatingSystem.1.0'),
          i18n.t('planDetails:personal.requirements.operatingSystem.1.1'),
          i18n.t('planDetails:personal.requirements.operatingSystem.1.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.operatingSystem.2.0'),
          i18n.t('planDetails:community.requirements.operatingSystem.2.1'),
          i18n.t('planDetails:community.requirements.operatingSystem.2.2'),
        ],
      ],
      browser: [
        [
          i18n.t('planDetails:personal.requirements.browser.0.0'),
          i18n.t('planDetails:personal.requirements.browser.0.1'),
          i18n.t('planDetails:personal.requirements.browser.0.2'),
        ],
        [
          i18n.t('planDetails:personal.requirements.browser.1.0'),
          i18n.t('planDetails:personal.requirements.browser.1.1'),
          i18n.t('planDetails:personal.requirements.browser.1.2'),
        ],
        [
          i18n.t('planDetails:personal.requirements.browser.2.0'),
          i18n.t('planDetails:personal.requirements.browser.2.1'),
          i18n.t('planDetails:personal.requirements.browser.2.2'),
        ],
        [
          i18n.t('planDetails:personal.requirements.browser.3.0'),
          i18n.t('planDetails:personal.requirements.browser.3.1'),
          i18n.t('planDetails:personal.requirements.browser.3.2'),
        ],
        [
          i18n.t('planDetails:personal.requirements.browser.4.0'),
          i18n.t('planDetails:personal.requirements.browser.4.1'),
          i18n.t('planDetails:personal.requirements.browser.4.2'),
        ],
      ],
    },
  },
  professional: {
    summary: i18n.t('planDetails:professional.summary'),
    topReasons: {
      header: i18n.t('planDetails:professional.topReasons.header'),
      points: [
        i18n.t('planDetails:professional.topReasons.points.0'),
        i18n.t('planDetails:professional.topReasons.points.1'),
        i18n.t('planDetails:professional.topReasons.points.2'),
        i18n.t('planDetails:professional.topReasons.points.3'),
        i18n.t('planDetails:professional.topReasons.points.4'),
      ],
    },
    summaryEnd: i18n.t('planDetails:professional.summaryEnd'),
    features: [
      {
        featureTitle: i18n.t(
          'planDetails:professional.features.0.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:professional.features.0.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:professional.features.1.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:professional.features.1.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:professional.features.2.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:professional.features.2.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:professional.features.3.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:professional.features.3.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:professional.features.4.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:professional.features.4.featureDescription'
        ),
      },
    ],
    advantages: [],
    requirements: {
      operatingSystem: [
        [
          i18n.t('planDetails:professional.requirements.operatingSystem.0.0'),
          i18n.t('planDetails:professional.requirements.operatingSystem.0.1'),
          i18n.t('planDetails:professional.requirements.operatingSystem.0.2'),
        ],
        [
          i18n.t('planDetails:professional.requirements.operatingSystem.1.0'),
          i18n.t('planDetails:professional.requirements.operatingSystem.1.1'),
          i18n.t('planDetails:professional.requirements.operatingSystem.1.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.operatingSystem.2.0'),
          i18n.t('planDetails:community.requirements.operatingSystem.2.1'),
          i18n.t('planDetails:community.requirements.operatingSystem.2.2'),
        ],
      ],
      browser: [
        [
          i18n.t('planDetails:professional.requirements.browser.0.0'),
          i18n.t('planDetails:professional.requirements.browser.0.1'),
          i18n.t('planDetails:professional.requirements.browser.0.2'),
        ],
        [
          i18n.t('planDetails:professional.requirements.browser.1.0'),
          i18n.t('planDetails:professional.requirements.browser.1.1'),
          i18n.t('planDetails:professional.requirements.browser.1.2'),
        ],
        [
          i18n.t('planDetails:professional.requirements.browser.2.0'),
          i18n.t('planDetails:professional.requirements.browser.2.1'),
          i18n.t('planDetails:professional.requirements.browser.2.2'),
        ],
        [
          i18n.t('planDetails:professional.requirements.browser.3.0'),
          i18n.t('planDetails:professional.requirements.browser.3.1'),
          i18n.t('planDetails:professional.requirements.browser.3.2'),
        ],
        [
          i18n.t('planDetails:professional.requirements.browser.4.0'),
          i18n.t('planDetails:professional.requirements.browser.4.1'),
          i18n.t('planDetails:professional.requirements.browser.4.2'),
        ],
      ],
    },
  },
  organization: {
    summary: i18n.t('planDetails:organization.summary'),
    topReasons: {
      header: i18n.t('planDetails:organization.topReasons.header'),
      points: [
        i18n.t('planDetails:organization.topReasons.points.0'),
        i18n.t('planDetails:organization.topReasons.points.1'),
        i18n.t('planDetails:organization.topReasons.points.2'),
        i18n.t('planDetails:organization.topReasons.points.3'),
        i18n.t('planDetails:organization.topReasons.points.4'),
      ],
    },
    summaryEnd: i18n.t('planDetails:organization.summaryEnd'),
    features: [
      {
        featureTitle: i18n.t(
          'planDetails:organization.features.0.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:organization.features.0.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:organization.features.1.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:organization.features.1.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:organization.features.2.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:organization.features.2.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:organization.features.3.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:organization.features.3.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:organization.features.4.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:organization.features.4.featureDescription'
        ),
      },
    ],
    advantages: [
      {
        advantageTitle: i18n.t(
          'planDetails:organization.advantages.0.advantageTitle'
        ),
        advantagePoints: [
          i18n.t('planDetails:organization.advantages.0.advantagePoints.0'),
          i18n.t('planDetails:organization.advantages.0.advantagePoints.1'),
          i18n.t('planDetails:organization.advantages.0.advantagePoints.2'),
          i18n.t('planDetails:organization.advantages.0.advantagePoints.3'),
          i18n.t('planDetails:organization.advantages.0.advantagePoints.4'),
        ],
      },
      {
        advantageTitle: i18n.t(
          'planDetails:organization.advantages.1.advantageTitle'
        ),
        advantagePoints: [
          i18n.t('planDetails:organization.advantages.1.advantagePoints.0'),
          i18n.t('planDetails:organization.advantages.1.advantagePoints.1'),
          i18n.t('planDetails:organization.advantages.1.advantagePoints.2'),
          i18n.t('planDetails:organization.advantages.1.advantagePoints.3'),
        ],
      },
      {
        advantageTitle: i18n.t(
          'planDetails:organization.advantages.2.advantageTitle'
        ),
        advantagePoints: [
          i18n.t('planDetails:organization.advantages.2.advantagePoints.0'),
          i18n.t('planDetails:organization.advantages.2.advantagePoints.1'),
          i18n.t('planDetails:organization.advantages.2.advantagePoints.2'),
        ],
      },
      {
        advantageTitle: i18n.t(
          'planDetails:organization.advantages.3.advantageTitle'
        ),
        advantagePoints: [
          i18n.t('planDetails:organization.advantages.3.advantagePoints.0'),
          i18n.t('planDetails:organization.advantages.3.advantagePoints.1'),
          i18n.t('planDetails:organization.advantages.3.advantagePoints.2'),
          i18n.t('planDetails:organization.advantages.3.advantagePoints.3'),
        ],
      },
    ],
    requirements: {
      operatingSystem: [
        [
          i18n.t('planDetails:organization.requirements.operatingSystem.0.0'),
          i18n.t('planDetails:organization.requirements.operatingSystem.0.1'),
          i18n.t('planDetails:organization.requirements.operatingSystem.0.2'),
        ],
        [
          i18n.t('planDetails:organization.requirements.operatingSystem.1.0'),
          i18n.t('planDetails:organization.requirements.operatingSystem.1.1'),
          i18n.t('planDetails:organization.requirements.operatingSystem.1.2'),
        ],
        [
          i18n.t('planDetails:community.requirements.operatingSystem.2.0'),
          i18n.t('planDetails:community.requirements.operatingSystem.2.1'),
          i18n.t('planDetails:community.requirements.operatingSystem.2.2'),
        ],
      ],
      browser: [
        [
          i18n.t('planDetails:organization.requirements.browser.0.0'),
          i18n.t('planDetails:organization.requirements.browser.0.1'),
          i18n.t('planDetails:organization.requirements.browser.0.2'),
        ],
        [
          i18n.t('planDetails:organization.requirements.browser.1.0'),
          i18n.t('planDetails:organization.requirements.browser.1.1'),
          i18n.t('planDetails:organization.requirements.browser.1.2'),
        ],
        [
          i18n.t('planDetails:organization.requirements.browser.2.0'),
          i18n.t('planDetails:organization.requirements.browser.2.1'),
          i18n.t('planDetails:organization.requirements.browser.2.2'),
        ],
        [
          i18n.t('planDetails:organization.requirements.browser.3.0'),
          i18n.t('planDetails:organization.requirements.browser.3.1'),
          i18n.t('planDetails:organization.requirements.browser.3.2'),
        ],
        [
          i18n.t('planDetails:organization.requirements.browser.4.0'),
          i18n.t('planDetails:organization.requirements.browser.4.1'),
          i18n.t('planDetails:organization.requirements.browser.4.2'),
        ],
      ],
    },
  },
  trialLicense: {
    summary: i18n.t('planDetails:boardmakerLicense.summary'),
    topReasons: {
      header: i18n.t('planDetails:boardmakerLicense.topReasons.header'),
      points: [
        i18n.t('planDetails:boardmakerLicense.topReasons.points.1'),
        i18n.t('planDetails:boardmakerLicense.topReasons.points.2'),
        i18n.t('planDetails:boardmakerLicense.topReasons.points.3'),
      ],
    },
    summaryEnd: i18n.t('planDetails:boardmakerLicense.summaryEnd'),
    features: [
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.0.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.0.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.1.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.1.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.2.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.2.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.3.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.3.featureDescription'
        ),
      },
    ],
    advantages: [],
    requirements: {
      operatingSystem: [
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.2'
          ),
        ],
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.2'
          ),
        ],
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.2'
          ),
        ],
      ],
      browser: [],
    },
  },
  boardmakerLicense: {
    summary: i18n.t('planDetails:boardmakerLicense.summary'),
    topReasons: {
      header: i18n.t('planDetails:boardmakerLicense.topReasons.header'),
      points: [
        i18n.t('planDetails:boardmakerLicense.topReasons.points.1'),
        i18n.t('planDetails:boardmakerLicense.topReasons.points.2'),
        i18n.t('planDetails:boardmakerLicense.topReasons.points.3'),
      ],
    },
    summaryEnd: i18n.t('planDetails:boardmakerLicense.summaryEnd'),
    features: [
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.0.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.0.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.1.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.1.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.2.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.2.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.3.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.3.featureDescription'
        ),
      },
    ],
    advantages: [],
    requirements: {
      operatingSystem: [
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.2'
          ),
        ],
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.2'
          ),
        ],
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.2'
          ),
        ],
      ],
      browser: [],
    },
  },
  studio: {
    summary: '',
    topReasons: {
      header: '',
      points: [],
    },
    summaryEnd: '',
    features: [],
    advantages: [],
    requirements: {
      operatingSystem: [
        [
          i18n.t('planDetails:studio.requirements.operatingSystem.0.0'),
          i18n.t('planDetails:studio.requirements.operatingSystem.0.1'),
          i18n.t('planDetails:studio.requirements.operatingSystem.0.2'),
        ],
      ],
      browser: [],
    },
  },
  scmp: {
    summary: '',
    topReasons: {
      header: '',
      points: [],
    },
    summaryEnd: '',
    features: [],
    advantages: [],
    requirements: {
      operatingSystem: [
        [
          i18n.t('planDetails:scmp.requirements.operatingSystem.0.0'),
          i18n.t('planDetails:scmp.requirements.operatingSystem.0.1'),
          i18n.t('planDetails:scmp.requirements.operatingSystem.0.2'),
        ],
        [
          i18n.t('planDetails:scmp.requirements.operatingSystem.1.0'),
          i18n.t('planDetails:scmp.requirements.operatingSystem.1.1'),
          i18n.t('planDetails:scmp.requirements.operatingSystem.1.2'),
        ],
        [
          i18n.t('planDetails:scmp.requirements.operatingSystem.2.0'),
          i18n.t('planDetails:scmp.requirements.operatingSystem.2.1'),
          i18n.t('planDetails:scmp.requirements.operatingSystem.2.2'),
        ],
      ],
      eyeTracker: [
        i18n.t('planDetails:scmp.requirements.eyeTracker.0.0'),
        i18n.t('planDetails:scmp.requirements.eyeTracker.0.1'),
        i18n.t('planDetails:scmp.requirements.eyeTracker.0.2'),
        i18n.t('planDetails:scmp.requirements.eyeTracker.0.3'),
        i18n.t('planDetails:scmp.requirements.eyeTracker.0.4'),
        i18n.t('planDetails:scmp.requirements.eyeTracker.0.5'),
      ],
      browser: [],
    },
  },
  licenseRequest: {
    summary: i18n.t('planDetails:boardmakerLicense.summary'),
    topReasons: {
      header: i18n.t('planDetails:boardmakerLicense.topReasons.header'),
      points: [
        i18n.t('planDetails:boardmakerLicense.topReasons.points.1'),
        i18n.t('planDetails:boardmakerLicense.topReasons.points.2'),
        i18n.t('planDetails:boardmakerLicense.topReasons.points.3'),
      ],
    },
    summaryEnd: i18n.t('planDetails:boardmakerLicense.summaryEnd'),
    features: [
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.0.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.0.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.1.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.1.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.2.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.2.featureDescription'
        ),
      },
      {
        featureTitle: i18n.t(
          'planDetails:boardmakerLicense.features.3.featureTitle'
        ),
        featureDescription: i18n.t(
          'planDetails:boardmakerLicense.features.3.featureDescription'
        ),
      },
    ],
    advantages: [],
    requirements: {
      operatingSystem: [
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.0.2'
          ),
        ],
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.1.2'
          ),
        ],
        [
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.0'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.1'
          ),
          i18n.t(
            'planDetails:boardmakerLicense.requirements.operatingSystem.2.2'
          ),
        ],
      ],
      browser: [],
    },
  },
};
