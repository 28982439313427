import Oidc from 'oidc-client';
import isBrowser from '@util/isBrowser';
/* eslint-disable */
///////////////////////////////
// config
///////////////////////////////
Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.DEBUG;

function log() {
  return;
  //document.getElementById("out").innerText = ""

  // Array.prototype.forEach.call(arguments, function(msg) {
  //   if (msg instanceof Error) {
  //     msg = 'Error: ' + msg.message;
  //   } else if (typeof msg !== 'string') {
  //     msg = JSON.stringify(msg, null, 2);
  //   }
  //   //document.getElementById("out").innerHTML += msg + "\r\n"
  //   console.log(msg);
  // });
}

const APP_ROOT = isBrowser ? window.location.origin : '';
var settings = {
  authority: process.env.SSO_URL,
  client_id: 'bmo-subscription',
  redirect_uri: `${APP_ROOT}/authredirect/`,
  popup_redirect_uri: `${APP_ROOT}/signinpopupcallback/`,
  response_type: 'id_token token',
  scope: 'openid profile bm-services',
  post_logout_redirect_uri: `${APP_ROOT}`,
  silent_redirect_uri: `${APP_ROOT}/silentrenew/`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  // accessTokenExpiringNotificationTime: 3585,
  // accessTokenExpiringNotificationTime: 300,
  popupWindowFeatures:
    'location=no,toolbar=no,width=500,height=815,left=100,top=100',
  // silentRequestTimeout: 20 * 1000,
};
export const mgr = isBrowser ? new Oidc.UserManager(settings) : { events: {} };

///////////////////////////////
// events
///////////////////////////////
// mgr.events.addAccessTokenExpiring(function() {
//   console.log('token expiring');
//   log('token expiring');
// });
if (isBrowser) {
  mgr.events.addAccessTokenExpired(function() {
    console.log('token expired');
    log('token expired');
  });

  mgr.events.addSilentRenewError(function(e) {
    console.log('silent renew error', e.message);
    log('silent renew error', e.message);
  });

  // mgr.events.addUserLoaded(function(user) {
  //   console.log("user loaded", user)
  //   mgr.getUser().then(function() {
  //     console.log("getUser loaded user after userLoaded event fired")
  //   })
  // })

  mgr.events.addUserUnloaded(function(e) {
    console.log('user unloaded');
  });

  mgr.events.addUserSignedOut(function() {
    clearState();
    mgr
      .signoutRedirect()
      .then(function(resp) {
        clearState();
        console.log('signing out...');
      })
      .catch(function(err) {
        // console.log(err);
      });
  });
}

///////////////////////////////
// functions for UI elements
///////////////////////////////
function clearState() {
  mgr
    .clearStaleState()
    .then(function() {
      log('clearStateState success');
    })
    .catch(function(e) {
      log('clearStateState error', e.message);
    });
}

// function getUser() {
//   return mgr
//     .getUser()
//     .then(function(user) {
//       log('got user', user);
//       return user;
//     })
//     .catch(function(err) {
//       log(err);
//     });
// }

// function removeUser() {
//   mgr
//     .removeUser()
//     .then(function() {
//       log('user removed');
//     })
//     .catch(function(err) {
//       log(err);
//     });
// }

export function startSigninMainWindow() {
  mgr
    .signinRedirect()
    .then(function() {
      log('signinRedirect done');
    })
    .catch(function(err) {
      log(err);
    });
}

// function endSigninMainWindow() {
//   mgr
//     .signinRedirectCallback()
//     .then(function(user) {
//       log('signed in', user);
//     })
//     .catch(function(err) {
//       log(err);
//     });
// }

// function startSigninMainWindowDiffCallbackPage() {
//   mgr
//     .signinRedirect({
//       state: 'some data',
//       redirect_uri: 'http://localhost:8000/identityserver-sample-callback.html',
//     })
//     .then(function() {
//       log('signinRedirect done');
//     })
//     .catch(function(err) {
//       log(err);
//     });
// }

export function popupSignin() {
  return mgr
    .signinPopup()
    .then(function(user) {
      log('signed in', user);
      return user;
    })
    .catch(function(err) {
      log(err);
    });
}

// function popupSignout() {
//   mgr
//     .signoutPopup()
//     .then(function() {
//       log('signed out');
//     })
//     .catch(function(err) {
//       log(err);
//     });
// }

export function iframeSignin() {
  return mgr
    .signinSilent()
    .then(function(user) {
      log('signed in', user);
      return user;
    })
    .catch(function(err) {
      log(err);
    });
}

// function querySessionStatus() {
//   mgr
//     .querySessionStatus()
//     .then(function(status) {
//       log("user's session status", status);
//     })
//     .catch(function(err) {
//       log(err);
//     });
// }

export function startSignoutMainWindow() {
  //   mgr
  //     .signoutRedirect()
  //     .then(function(resp) {
  mgr
    .signoutRedirect()
    .then(function(resp) {
      clearState();
      console.log('signed out', resp);
    })
    .catch(function(err) {
      log(err);
    });
}

// function endSignoutMainWindow() {
//   mgr
//     .signoutRedirectCallback()
//     .then(function(resp) {
//       log('signed out', resp);
//     })
//     .catch(function(err) {
//       log(err);
//     });
// }
