import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import * as en from './locales/en';
import * as de from './locales/de';
import * as no from './locales/no';
import * as es from './locales/es';
import * as sv from './locales/sv';
import * as fi from './locales/fi';
import * as ru from './locales/ru';
import * as da from './locales/da';
import * as fr from './locales/fr';
import * as it from './locales/it';
import * as pl from './locales/pl';
import * as pt from './locales/pt';

const resources = {
  en,
  de,
  no,
  es,
  sv,
  fi,
  ru,
  da,
  fr,
  it,
  pl,
  pt,
};

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: [
      'common',
      'plans',
      'page',
      'planDetails',
      'comparison',
      'countries',
      'compare',
    ],
    defaultNS: 'common',
    // backend: {
    //   loadPath: 'locales/{{lng}}/{{ns}}.json',
    // },
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    whitelist: [
      'en',
      'de',
      'no',
      'es',
      'sv',
      'fi',
      'ru',
      'da',
      'fr',
      'it',
      'pl',
      'pt',
    ],
    react: {
      useSuspense: true,
    },
    interpolation: {
      format: function(value, format, lng) {
        //if(format==='currency') return formatCurrency(value);
        return value;
      },
    },
  });

i18n.on('languageChanged', function(lng) {
  moment.locale(lng);
});

export default i18n;
