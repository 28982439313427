import React from 'react';
import { navigate, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { useUser } from '@util/providers/AuthProvider';

import LogInButton from '@components/Account/LogInButton';
import UserMenu from '@components/Account/UserMenu';

import logo from '../images/logo_boardmaker.png';
import RegionSelector from '@components/RegionSelector';

const Header = props => {
  return (
    <FixedHeader>
      <Navbar light expand="xs" className="navbar-custom">
        <div className="container">
          <a
            className="navbar-brand pl-sm-3 py-3 d-inline-block d-sm-none"
            href="/"
            style={{ width: 180 }}
          >
            <img src={logo} alt="Boardmaker" className="img-fluid h-auto" />
          </a>
          <a
            className="navbar-brand pl-0 pl-sm-3 pl-md-0 py-3 d-none d-sm-inline-block"
            href="/"
            style={{ width: 230 }}
          >
            <img src={logo} alt="Boardmaker" className="img-fluid h-auto" />
          </a>
          {!props.isRetrievingUserInfo && (
            <>
              <Nav className="ml-auto d-md-none" navbar>
                <MobileMenu />
              </Nav>
              <Nav className="ml-auto d-none d-md-flex" navbar>
                <DesktopMenu />
              </Nav>
            </>
          )}
        </div>
      </Navbar>
    </FixedHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

function DesktopMenu() {
  return (
    <>
      <RegionSelector />
      <BMOLink />
      <DownloadsLink />
      <UserMenu />
    </>
  );
}

function MobileMenu() {
  const { t } = useTranslation();
  const { ssoUser, signOut } = useUser();
  return (
    <>
      <RegionSelector />
      <UncontrolledDropdown
        nav
        inNavbar
        className="dropdown-custom d-block d-md-none dropdown-mobile"
      >
        <DropdownToggle nav className="dropdown-toggle">
          <span className="navbar-toggler-icon"></span>
        </DropdownToggle>
        <DropdownMenu right className="mb-3 mb-md-0 py-3">
          {ssoUser && (
            <DropdownItem header className="pl-3">
              {ssoUser.given_name}
            </DropdownItem>
          )}
          <BMOLink mobile />
          <DownloadsLink mobile />

          {ssoUser ? (
            <DropdownItem onClick={signOut}>{t('account.logOut')}</DropdownItem>
          ) : (
            <LogInButton className="btn btn-link" mobile />
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

function BMOLink({ mobile = false }) {
  const { t } = useTranslation();
  const { bmoUser } = useUser();

  const linkText = t('boardmakerHome');
  const handleClick = () => {
    navigate('/redirect', {
      state: {
        extension: bmoUser ? '/You/Home' : '',
      },
    });
  };

  if (mobile)
    return <DropdownItem onClick={handleClick}> {linkText}</DropdownItem>;

  return (
    <NavItem className="d-none d-md-block">
      <NavLink tag="button" onClick={handleClick}>
        {linkText}
      </NavLink>
    </NavItem>
  );
}

function DownloadsLink({ mobile = false }) {
  const { t } = useTranslation();
  const linkText = t('action.downloads');

  if (mobile) {
    return (
      <Link to="/downloads" className="dropdown-item">
        {linkText}
      </Link>
    );
  }

  return (
    <NavItem>
      <Link to="/downloads" className="nav-link">
        {linkText}
      </Link>
    </NavItem>
  );
}

const FixedHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1001;
`;

export default Header;
