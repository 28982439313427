import numeral from 'numeral';
import _ from 'lodash';

function formatPlanData(array, prop, key) {
  return _.keyBy(
    array &&
      array.map(x => {
        return {
          ...x,
          [prop]: _.keyBy(x[prop], key),
        };
      }),
    key
  );
}

export function boardmakerToSSOLanguage(language) {
  switch (language) {
    case 'da':
      return 'da-DK';
    case 'de':
      return 'de-DE';
    case 'es':
      return 'es';
    case 'fr':
      return 'fr-FR';
    case 'it':
      return 'it-IT';
    case 'no':
      return 'nb-NO';
    case 'pt':
      return 'pt-BR';
    case 'sv':
      return 'sv-SE';
    default:
      return language;
  }
}

export function getSubscriptionCurrency(currency) {
  return currency === 'CAD' ? 'CAD' : 'USD';
}

export function sortList(list) {
  return list.sort(function(a, b) {
    var itemA = a?.name?.toUpperCase();
    var itemB = b?.name?.toUpperCase();
    if (itemA < itemB) {
      return -1;
    } else if (itemA > itemB) {
      return 1;
    } else {
      return 0;
    }
  });
}

//* formats and merges remote and static catalog data
export function formatCatalog(a, b) {
  let merged = _.values(
    _.merge(
      formatPlanData(a, 'ratePlans', 'id'),
      formatPlanData(b, 'ratePlans', 'id')
    )
  );

  //* override zuora product names with static product name if it exists
  merged = merged.map(p => {
    if (p.displayName) {
      return {
        ...p,
        name: p.displayName,
      };
    }

    return p;
  });

  //* convert rate plans back to array
  return merged.map(p => {
    return {
      ...p,
      ratePlans: _.values(p.ratePlans),
    };
  });
}

/**
 * Formats currency according to currency selected
 * TODO: update when adding new currencies
 *
 * @export
 * @param {*} value
 * @returns formatted currency
 */
export function formatCurrency(
  value,
  selectedCurrency,
  { withLabel = true, trim = false } = {}
) {
  const usdFormatString = trim ? '$0,0' : '$0,0.00';
  //const usdFormatString = '$0,0.00';
  return withLabel
    ? `${numeral(value).format(usdFormatString)} ${selectedCurrency}`
    : `${numeral(value).format(usdFormatString)}`;
}

//From https://newbedev.com/fastest-way-to-detect-external-urls
export const isExternalURL = url =>
  (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
  checkDomain(window.location.href) !== checkDomain(url);

var checkDomain = function(url) {
  if (url.indexOf('//') === 0) {
    url = window.location.protocol + url;
  }
  return url
    .toLowerCase()
    .replace(/([a-z])?:\/\//, '$1')
    .split('/')[0];
};
