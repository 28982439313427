import React from 'react';
import ErrorBoundary from '@util/Errors/ErrorBoundary';
import Layout from '@components/layout';
import Providers from '@util/providers';

export default ({ element, props }) => {
  if (props.path === '/login/') {
    return element;
  }
  return (
    <ErrorBoundary>
      <Providers>
        <Layout {...props}>{element}</Layout>
      </Providers>
    </ErrorBoundary>
  );
};
