import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function({ heading, children, isOpen, dismiss }) {
  const { t } = useTranslation();
  return (
    <Alert color="danger" isOpen={isOpen} toggle={dismiss}>
      {<h4 className="alert-heading">{heading || t('errors.error')}</h4>}
      {children}
    </Alert>
  );
}
