import { navigate } from 'gatsby';

const ExemptPaths = ['/downloads', '/downloads/'];

export default function navigationWrapper(targetPath) {
  // Ensure we never navigate automatically off of any exempted paths
  if (ExemptPaths.includes(window.location.pathname)) {
    return;
  }
  // Ensure we don't navigate to the same page we're already on
  if (targetPath === window.location.pathname) {
    return;
  }
  navigate(targetPath);
}
