/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import wrapWithAuthProvider from '@util/wrapWithAuthProvider';
import wrapWithLayout from '@util/wrapWithLayout';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.AI_INSTRUMENTATION_KEY,
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();

export const wrapRootElement = wrapWithAuthProvider;

export const wrapPageElement = wrapWithLayout;

export const onRouteUpdate = ({ location, prevLocation }) => {
  appInsights.trackPageView();
};
