import { useState, useEffect } from 'react';
import SecureStorage from 'secure-web-storage';
import CryptoJS from 'crypto-js';

import isBrowser from '@util/isBrowser';

const storageMethod = isBrowser ? sessionStorage : {};

var secureStorage = new SecureStorage(storageMethod, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, process.env.STORAGE_SECRET_KEY);

    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, process.env.STORAGE_SECRET_KEY);

    data = data.toString();

    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, process.env.STORAGE_SECRET_KEY);

    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  },
});

export const useStateWithSessionStorage = (
  storageKey,
  defaultValue,
  encrypt = process.env.ENABLE_DEV_FEATURES === 'true'
) => {
  const [value, setValue] = useState(() => {
    if (isBrowser) {
      if (encrypt) {
        return secureStorage.getItem(storageKey) || defaultValue;
      }

      return JSON.parse(storageMethod.getItem(storageKey)) || defaultValue;
    }

    return null;
  });

  useEffect(() => {
    if (isBrowser) {
      if (encrypt) {
        secureStorage.setItem(storageKey, value);
      } else {
        storageMethod.setItem(storageKey, JSON.stringify(value));
      }
    }
  }, [value, encrypt, storageKey]);
  return [value, setValue];
};
