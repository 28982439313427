import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { usePlan } from '@util/providers/Plan';
import i18n from '../../i18n/i18n';
import FlagIcon from '../../images/FlagIcon';
import RegionSelect from '@components/miscellaneous/RegionSelect';
import LanguageSelect from '@components/miscellaneous/LanguageSelect';
import { useLicense } from '@util/providers/License';
import { useLocale } from '@util/providers/Locale';

export default function RegionSelector({ className }) {
  const { locale } = useLocale();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={className}>
      <button
        onClick={() => setIsModalOpen(true)}
        data-testid="CurrencySelect"
        className="btn btn-link"
      >
        <FlagIcon
          code={locale?.localeDetails?.isoCode.toLowerCase()}
          className="mr-2 pr-2"
        />
        {i18n.language.toUpperCase()}
      </button>
      <RegionModal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} />
    </div>
  );
}

function RegionModal({ isOpen, toggle }) {
  const { t } = useTranslation();
  const { plans } = usePlan();
  const { licensePlans } = useLicense();

  // eslint-disable-next-line
  useEffect(() => toggle(), [plans, licensePlans]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} charCode="">
        {t('currencySelector.header')}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-6">
            <div className="pb-2">{t('forms.country')}</div>
            <RegionSelect />
          </div>
          <div className="col-6">
            <div className="pb-2">{t('forms.language')}</div>
            <LanguageSelect />
          </div>
        </div>
        <div className="bg-light text-muted p-4 mt-4">
          <p className="p-0 m-0" style={{ lineHeight: 1.25, fontSize: '75%' }}>
            {t('currencySelector.disclaimer.USD')}
          </p>
          <p className="pt-1 p-0 m-0" style={{ fontSize: '75%' }}>
            {t('partner.contactUs')}
            <a href={process.env.CONTACT_US} target="_blank">
              {t('partner.contactCustomerService')}
            </a>
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="pb-5">
        <button
          type="button"
          id="ContinueLocaleFooter"
          onClick={toggle}
          className="btn btn-primary"
        >
          {t('action.continue')}
        </button>
      </ModalFooter>
    </Modal>
  );
}
