import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import { unitedStatesLocale, canadianLocale } from '@data/locales';
import { useLocale } from '@util/providers/Locale';
import URLS from '@config/urls';
import {
  mgr,
  iframeSignin,
  startSignoutMainWindow,
  popupSignin,
} from '@config/oidc-config';
import i18n from '../../i18n/i18n';
import navigationWrapper from '../navigationWrapper';

/* eslint-disable */
class SsoUser {
  constructor(access_token, name, expires_at, auth_time, given_name) {
    this.access_token = access_token;
    this.name = name;
    this.expires_at = expires_at;
    this.auth_time = auth_time;
    this.given_name = given_name;
  }
}

class BmoUser {
  constructor(
    firstName,
    lastName,
    email,
    accountType,
    subscriptionInfo,
    zuoraInfo,
    PONumber,
    studioLicenses
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.accountType = accountType;
    this.subscriptionInfo = subscriptionInfo;
    this.zuoraInfo = zuoraInfo;
    this.PONumber = PONumber;
    this.studioLicenses = studioLicenses;
  }
}

const UserContext = createContext({});

export const loadingStatus = {
  LOG_IN_SSO: 'Logging in with Tobii Dynavox SSO account',
  GET_SSO: 'Checking for Tobii Dynavox SSO account',
  GET_BMO: 'Checking for Boardmaker user',
  GET_BMO_SUB: 'Getting subscription information',
  GET_FUTURE_AMENDMENTS: 'Getting Pending Modifications',
  GET_LICENSES: 'Getting Licenses',
  DONE: 'DONE!',
};

export default function AuthProvider({ children }) {
  const [status, setStatus] = useState();
  const [ssoUser, setSsoUser] = useState();
  const [bmoUser, setBmoUser] = useState();
  const [shouldGetBmoUser, setShouldGetBmoUser] = useState(false);

  const {
    selectedCurrency,
    setSelectedCurrency,
    setLocale,
    selectedLanguage,
    setSelectedLanguage,
    locale,
  } = useLocale();
  redirectByGeolocation();
  selectedLanguage && i18n.changeLanguage(selectedLanguage.code);

  //* attempts silent sign in
  //* triggered by: full page refresh, post create account log in
  async function signInSilent({ getBmoUser = true, geoLocate = true } = {}) {
    getBmoUser
      ? setStatus(loadingStatus.GET_SSO)
      : setStatus(loadingStatus.LOG_IN_SSO);
    setShouldGetBmoUser(getBmoUser);
    !bmoUser && geoLocate && getGeoLocation();
    const user = await iframeSignin();
    //* update user from undefined to null to show that there was an attempt to log in silently, but the user was not already logged in
    !user && addSsoUser(null);
  }

  //* sign in
  //* triggered by: user interaction with log in button
  async function signIn() {
    setStatus(loadingStatus.LOG_IN_SSO);
    await popupSignin();
    setStatus(loadingStatus.DONE);
  }

  //* sign out
  function signOut() {
    startSignoutMainWindow();
  }

  //* add sso user
  function addSsoUser(user) {
    setSsoUser(
      user
        ? new SsoUser(
            user.access_token,
            user.profile.name,
            user.expires_at,
            user.profile.auth_time,
            user.profile.given_name
          )
        : null
    );
  }

  //* on mount
  useEffect(() => {
    signInSilent();

    //* runs when a user is loaded either by user interaction or silent sign in
    mgr.events.addUserLoaded(function(user) {
      // setStatus(loadingStatus.LOG_IN_SSO);
      addSsoUser(user || null);
    });
    // eslint-disable-next-line
  }, []);

  //* get bmo user information
  async function getBmoUser() {
    //* check for bmo user
    setStatus(loadingStatus.GET_BMO);
    await axios
      .get(URLS.CREATE_BMO_ACCOUNT, {
        headers: {
          Authorization: `Bearer ${ssoUser.access_token}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        },
      })
      .then(res => {
        const user = res.data;
        setBmoUser(
          new BmoUser(
            user.firstName,
            user.lastName,
            user.email,
            user.accountType,
            user.subscriptionInfo
          )
        );

        if (
          user.accountType === 'District' ||
          (user.accountType === 'Free' &&
            user?.subscriptionInfo.planType === 'District')
        ) {
          navigationWrapper('/downloads');
          if (window.location.hash.toLowerCase() === '#studentcenter') {
            navigationWrapper('/downloads/#studentcenter');
          }
        }
        if (user.subscriptionInfo) {
          getZuoraInfo();
          getLicenses(user.id);
        } else {
          //* done
          setStatus(loadingStatus.DONE);
        }
      })
      .catch(err => {
        //* done
        setStatus(loadingStatus.DONE);
        redirectByGeolocation();
      });
  }

  async function getZuoraInfo() {
    //* check for BMO subscription info
    setStatus(loadingStatus.GET_BMO_SUB);
    await axios
      .get(URLS.ZUORA_INFO, {
        headers: {
          Authorization: `Bearer ${ssoUser.access_token}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        },
      })
      .then(res => {
        const info = res.data;
        setBmoUser(prevBmoUser => ({
          ...prevBmoUser,
          zuoraInfo: info,
        }));
        info.basicInfo.currency === 'CAD'
          ? setLocale(canadianLocale)
          : setLocale(unitedStatesLocale);
        getFutureAmendments(info);
        if (!info.basicInfo.defaultPaymentMethod) {
          getPONumber(info.basicInfo.currency);
        }
        setStatus(loadingStatus.DONE);
      })
      .catch(err => {
        console.error('BMO user subscription not found');
        //* done
        setStatus(loadingStatus.DONE);
      });
  }

  async function getGeoLocation() {
    (!locale || JSON.stringify(locale) == JSON.stringify(unitedStatesLocale)) &&
      (await axios
        .get(URLS.GET_LOCATION)
        .then(res => {
          getLocationinfo(res.data);
        })
        .catch(err => {
          console.error('Location not found.');
        }));
  }

  async function getLocationinfo(location) {
    let url =
      process.env.INTERNAL_SERVICE_URL +
      'admin/regions/' +
      location?.isoCode?.toUpperCase();
    await axios
      .get(url)
      .then(res => {
        setLocale({
          localeDetails: res.data,
          geoLocated: true,
        });
        !window.location.href.includes('?lng') && getLanguageInfo(res.data);
        res.data.exclusive && navigationWrapper('/partner');
      })
      .catch(err => {
        console.error('Location Info not found: ' + err);
      });
  }

  async function getLanguageInfo(location) {
    let url =
      process.env.INTERNAL_SERVICE_URL +
      'admin/locales/' +
      location?.defaultLocale;
    await axios
      .get(url)
      .then(res => {
        setSelectedLanguage(res.data.name);
        i18n.changeLanguage(res.data.code.substring(0, 2));
      })
      .catch(err => {
        console.error('Location not found.');
      });
  }

  async function getLicenses(ssoId) {
    //* check for License Info
    setStatus(loadingStatus.GET_LICENSES);
    await axios
      .get(
        URLS.GET_LICENSES + '?userSsoId=' + ssoId + '&showInherited=' + true,
        {
          headers: {
            Authorization: `Bearer ${ssoUser.access_token}`,
          },
        }
      )
      .then(res => {
        const info = res.data;
        setBmoUser(prevBmoUser => ({
          ...prevBmoUser,
          studioLicenses: info,
        }));
      })
      .catch(err => {
        console.error('No studio licenses found.');
      });
  }

  async function getFutureAmendments(info) {
    //* check for BMO subscription info
    setStatus(loadingStatus.GET_FUTURE_AMENDMENTS);
    const subNumber = info.subscriptions[0].subscriptionNumber;
    await axios
      .get(
        URLS.FUTURE_AMENDMENTS +
          '?currency=' +
          info.basicInfo.currency +
          '&subscriptionNumber=' +
          subNumber,
        {
          headers: {
            Authorization: `Bearer ${ssoUser.access_token}`,
          },
        }
      )
      .then(res => {
        const info = res.data;
        setBmoUser(prevBmoUser => ({
          ...prevBmoUser,
          futureAmendments: info,
        }));
      })
      .catch(err => {
        console.error('No future amendments found');
      });
  }

  async function getPONumber(selectedCurrency) {
    //* check for BMO subscription info
    setStatus(loadingStatus.GET_BMO_SUB);
    await axios
      .get(URLS.GET_PO + '?currency=' + selectedCurrency, {
        headers: {
          Authorization: `Bearer ${ssoUser.access_token}`,
        },
      })
      .then(res => {
        const PONumber = res.data;
        setBmoUser(prevBmoUser => ({
          ...prevBmoUser,
          PONumber,
        }));
      })
      .catch(err => {
        console.error('BMO user subscription not found');
      });
  }

  async function redirectByGeolocation() {
    // Ensure we only navigate to one or the other of these routes. /partner takes priority.
    if (locale?.geoLocated === true && locale?.localeDetails?.exclusive == true) {
      navigationWrapper('/partner');
    } else if (
      !bmoUser &&
      locale?.localeDetails?.defaultToSubscription == false &&
      locale?.localeDetails?.exclusive == false &&
      locale?.geoLocated === true
    ) {
      navigationWrapper('/license');
    }
  }

  useEffect(() => {
    //* if a user exists, get BMO user information
    //* -- else --
    //* check for `null`... ssoUser is set to null when a silent sign in attempt is made but the user is not signed in
    if (ssoUser && ssoUser.access_token) {
      //* only fetch BMO user if it doesn't already exist
      //* -- and --
      //* we should get it
      if (!bmoUser && shouldGetBmoUser) {
        getBmoUser();
      } else {
        setStatus(loadingStatus.DONE);
      }
      setShouldGetBmoUser(true);
      if (window.location.href.includes('/downloads')) {
        navigationWrapper('/downloads/' + window.location.hash.toLowerCase());
      }
    } else if (ssoUser === null) {
      //* done
      setStatus(loadingStatus.DONE);
      redirectByGeolocation();
    }
    // eslint-disable-next-line
  }, [ssoUser]);

  return (
    <UserContext.Provider
      value={{
        ssoUser,
        bmoUser,
        getBmoUser,
        getZuoraInfo,
        signInSilent,
        signIn,
        signOut,
        userStatus: status,
        selectedCurrency,
        setSelectedCurrency,
        setBmoUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

//* hook to provide context to the app
export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within UserContext provider');
  }

  return context;
}
