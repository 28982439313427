import React, { createContext, useState, useContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
// eslint-disable-next-line
import { CancelToken } from 'axios';
import { useStateWithSessionStorage } from '../hooks/useStateWithSessionStorage';

import { useLocale } from '@util/providers/Locale';

const LicenseContext = createContext({});

const cancelSource = CancelToken.source();

const defaultValues = {
  licensePlans: null,
  USLicensePlans: null,
  CALicensePlans: null,
};
function LicenseProvider({ children }) {
  const [licensePlans, setLicensePlans] = useStateWithSessionStorage(
    'licensePlans',
    defaultValues.licensePlans
  );

  const [USLicensePlans, setUSLicensePlans] = useStateWithSessionStorage(
    'USLicensePlans',
    defaultValues.USLicensePlans
  );

  const [CALicensePlans, setCALicensePlans] = useStateWithSessionStorage(
    'CALicensePlans',
    defaultValues.CALicensePlans
  );

  const [contextValue, setContextValue] = useState({
    licensePlans,
    setLicensePlans,
    USLicensePlans,
    setUSLicensePlans,
    CALicensePlans,
    setCALicensePlans,
    resetLicensePlans: () => setLicensePlans(defaultValues.licensePlans),
    resetUSLicensePlans: () => setUSLicensePlans(defaultValues.USLicensePlans),
    resetCALicensePlans: () => setCALicensePlans(defaultValues.CALicensePlans),
  });

  const { locale, setSelectedCurrency } = useLocale();
  const fastspringKey = process.env.FASTSPRING_PRODUCT_KEY;
  const USShopifyKey = process.env.SHOPIFY_PRODUCT_KEY_US;
  const CAShopifyKey = process.env.SHOPIFY_PRODUCT_KEY_CA;
  const [
    // eslint-disable-next-line
    {
      data: licenseCatalogData,
      // eslint-disable-next-line
      loading: loadingLicenseCatalog,
      error: licenseCatalogError,
    },
    // eslint-disable-next-line
    getLicenseCatalog,
  ] = useAxios(
    `${process.env.SERVICE_URL}subscriptions/perpetual?productId=${fastspringKey}&currency=EURO`,
    {
      method: 'GET',
      cancelToken: cancelSource.token,
    }
  );

  const [
    // eslint-disable-next-line
    {
      data: USLicenseCatalogData,
      // eslint-disable-next-line
      loading: loadingUSLicenseCatalog,
      // eslint-disable-next-line
      error: USLicenseCatalogError,
    },
    // eslint-disable-next-line
    getUSLicenseCatalog,
  ] = useAxios(
    `${process.env.SERVICE_URL}subscriptions/perpetual?productId=${USShopifyKey}&currency=USD`,
    {
      method: 'GET',
      cancelToken: cancelSource.token,
    }
  );

  const [
    // eslint-disable-next-line
    {
      data: CALicenseCatalogData,
      // eslint-disable-next-line
      loading: loadingCALicenseCatalog,
      // eslint-disable-next-line
      error: CALicenseCatalogError,
    },
    // eslint-disable-next-line
    getCALicenseCatalog,
  ] = useAxios(
    `${process.env.SERVICE_URL}subscriptions/perpetual?productId=${CAShopifyKey}&currency=CAD`,
    {
      method: 'GET',
      cancelToken: cancelSource.token,
    }
  );

  useEffect(() => {
    setContextValue(contextValue => ({
      ...contextValue,
      licensePlans,
      USLicensePlans,
      CALicensePlans,
    }));
  }, [licensePlans, USLicensePlans, CALicensePlans]);

  //* fetch plans when locale changes
  useEffect(() => {
    locale &&
      licenseCatalogData &&
      setSelectedCurrency(
        locale.localeDetails?.isoCode === 'CA'
          ? 'CAD'
          : locale.localeDetails?.isoCode === 'US'
          ? 'USD'
          : licenseCatalogData.pricing[(locale.localeDetails?.isoCode)]
              ?.currency
      );
    // eslint-disable-next-line
  }, [locale]);

  //* set catalog when new data is received from the service
  useEffect(() => {
    if (licenseCatalogData) {
      var newLicensePlans = licenseCatalogData;
      if (USLicensePlans && locale?.localeDetails?.isoCode === 'US') {
        licenseCatalogData.pricing['US'] = USLicensePlans.pricing['USD'];
      }
      if (CALicensePlans && locale?.localeDetails?.isoCode === 'CA') {
        licenseCatalogData.pricing['CA'] = CALicensePlans.pricing['CAD'];
      }
      setLicensePlans(newLicensePlans);
    }

    // eslint-disable-next-line
  }, [licenseCatalogData]);

  //* set catalog when new data is received from the service
  useEffect(() => {
    if (USLicenseCatalogData) {
      setUSLicensePlans(USLicenseCatalogData);
    }
    // eslint-disable-next-line
  }, [USLicenseCatalogData]);

  //* set catalog when new data is received from the service
  useEffect(() => {
    if (CALicenseCatalogData) {
      setCALicensePlans(CALicenseCatalogData);
    }
    // eslint-disable-next-line
  }, [CALicenseCatalogData]);

  //* throw error if catalog fails to load
  useEffect(() => {
    if (licenseCatalogError) {
      throw new Error('Error retrieving plan catalog.');
    }
    // eslint-disable-next-line
  }, [licenseCatalogError]);

  return (
    <LicenseContext.Provider value={contextValue}>
      {children}
    </LicenseContext.Provider>
  );
}

function useLicense() {
  const context = useContext(LicenseContext);
  if (context === undefined) {
    throw new Error('useLicense must be used within a LicenseContext');
  }

  return context;
}

export { LicenseProvider, useLicense };
