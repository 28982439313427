import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import moment from 'moment';

import { useUser } from '@util/providers/AuthProvider';

import LogInButton from '@components/Account/LogInButton';

export default () => {
  const { t } = useTranslation();
  const { ssoUser, signOut } = useUser();

  if (!ssoUser) return <LogInButton className="btn btn-link" />;

  if (ssoUser)
    return (
      <UncontrolledDropdown nav inNavbar className="dropdown-custom">
        <DropdownToggle
          nav
          caret
          title={`last login: ${moment(ssoUser.auth_time * 1000).format(
            'l LTS'
          )}\nexpires: ${moment(ssoUser.expires_at * 1000).format('l LTS')}`}
        >
          {ssoUser.given_name}
        </DropdownToggle>
        <DropdownMenu right className="mb-3 mb-md-0">
          <DropdownItem onClick={signOut}>{t('account.logOut')}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
};
