import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem, NavItem, NavLink } from 'reactstrap';

import { useUser } from '@util/providers/AuthProvider';

export default function LogInButton({ className, mobile = false }) {
  const { t } = useTranslation();
  const { signIn } = useUser();

  const linkText = t('account.logIn');

  if (mobile) {
    return <DropdownItem onClick={signIn}>{linkText}</DropdownItem>;
  }

  return (
    <NavItem>
      <NavLink
        tag="button"
        className={className}
        id="LogInButton"
        datatestid="LogInButton"
        onClick={() => {
          return signIn();
        }}
      >
        {linkText}
      </NavLink>
    </NavItem>
  );
}
