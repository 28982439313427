import { formatCatalog } from '../../util/functions';
import { usePlan } from '@util/providers/Plan';

//* static plan details
import {
  staticPlanDetailsCommunity,
  staticPlanDetailsPersonal,
  staticPlanDetailsProfessional,
  staticPlanDetailsOrganization,
} from '@data';

export function getProductCatalog(plans, selectedCurrency) {
  function mergedPlanData() {
    return formatCatalog(
      [
        staticPlanDetailsPersonal(selectedCurrency),
        staticPlanDetailsProfessional(selectedCurrency),
      ],
      plans.productList
    );
  }

  function productCatalogResults() {
    return [
      staticPlanDetailsCommunity(),
      ...mergedPlanData().filter(p => p.active),
      staticPlanDetailsOrganization(),
    ];
  }
  //* merge static details for community and org
  return productCatalogResults;
}

export function useYearlySavings() {
  const { plans } = usePlan();
  if (plans == null) {
    return 0;
  }
  const p = plans.find(plan => plan.key === 'personal');
  const monthlyRate = p.ratePlans.find(rp => rp.isMonthly).price * 12;
  const yearlyRate = p.ratePlans.find(rp => rp.isYearly).price;
  const savings = ((monthlyRate - yearlyRate) / monthlyRate) * 100;
  return savings.toFixed();
}
