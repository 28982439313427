import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function RegionLoadingModal({ isOpen }) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} size="lg" centered>
      <ModalHeader>
        <p>{t('loadingRegions')}</p>
      </ModalHeader>
      <ModalBody className="text-center">
        <p>{t('pleaseWait')}</p>
      </ModalBody>
    </Modal>
  );
}
