import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import '@i18n/i18n';

import '../scss/entry/site.scss';

import styled, { ThemeProvider } from 'styled-components';
import Header from './header';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import LegalLink from '@components/Legal/LegalLink';

import { useUser, loadingStatus } from '@util/providers/AuthProvider';

//* debug info
import ResponsiveHelper from '../debug/ResponsiveHelper';

const Layout = ({ children }) => {
  const { userStatus } = useUser();
  const isRetrievingUserInfo = userStatus !== loadingStatus.DONE;
  return (
    <>
      <ThemeProvider
        theme={{
          brand: {
            primary: '#00af66',
          },
        }}
      >
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <>
              <Header
                siteTitle={data.site.siteMetadata.title}
                isRetrievingUserInfo={isRetrievingUserInfo}
              />
              <MainContentContainer className="content pb-4 pb-lg-5 mt-0">
                <main className="container">
                  {isRetrievingUserInfo ? <LoadingUser /> : children}
                </main>
              </MainContentContainer>
              <Footer
                className="mt-5 d-md-none"
                style={{
                  marginBottom: 150,
                }}
              >
                <FooterContent />
              </Footer>
              <Footer className="my-5 d-none d-md-block">
                <FooterContent />
              </Footer>
            </>
          )}
        />
      </ThemeProvider>
    </>
  );
};

function FooterContent() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
        <span>
          <a
            className="text-light"
            href={process.env.CONTACT_US}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('legal.contactUs')}
          </a>
        </span>
        <span>
          <a
            className="text-light"
            href="https://www.tobiidynavox.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('legal.privacyPolicy')}
          </a>
        </span>
        <span>
          <a
            className="text-light"
            href="https://us.tobiidynavox.com/pages/student-data-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('legal.studentPolicy')}
          </a>
        </span>
        <span>
          <LegalLink className="text-light" url="/BMOTerms.md">
            {t('legal.termsAndConditions')}
          </LegalLink>
        </span>
        <span>&copy; Tobii Dynavox, LLC {new Date().getFullYear()}</span>
      </div>

      <DebugFooter className="mt-1 text-white-50">
        v.{`#{Build.BuildNumber}#`}
        {process.env.RESPONSIVE_VIEW_DEBUGGER === 'enabled' && (
          <ResponsiveHelper />
        )}
      </DebugFooter>
    </>
  );
}

function LoadingUser() {
  const { t } = useTranslation();
  return (
    <>
      <SEO title={`${t('account.loadingUser')}...`} />
      <div className="row">
        <div className="col">
          <PageHeader>{t('account.loadingUser')}...</PageHeader>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const MainContentContainer = styled.div`
  padding-top: 6rem;
  min-height: 75vh;
`;

const Footer = styled.footer`
  margin-top: 0.5rem;
  text-align: center;
  span {
    display: inline-block;
    margin: 0 1rem;
  }
`;

const DebugFooter = styled.div`
  font-size: 0.875rem;
  border-radius: 0;
  margin-bottom: 0;

  .debug-links {
    display: inline-block;
    margin-left: 2rem;
  }
`;

export default Layout;
