import React, { createContext, useState, useContext, useEffect } from 'react';

import { useStateWithSessionStorage } from '../hooks/useStateWithSessionStorage';

const BillingPaymentContext = createContext({});

const defaultValues = {
  billingDetails: {},
  paymentDetails: {},
};
function BillingPaymentProvider({ children }) {
  const [billingDetails, setBillingDetails] = useStateWithSessionStorage(
    'billingDetails',
    defaultValues.billingDetails
  );
  const [paymentDetails, setPaymentDetails] = useStateWithSessionStorage(
    'paymentDetails',
    defaultValues.paymentDetails
  );

  //   const [state, dispatch] = useReducer(reducer, {})
  const [contextValue, setContextValue] = useState({
    billingDetails,
    setBillingDetails,
    resetBillingDetails: () => setBillingDetails(defaultValues.billingDetails),
    paymentDetails,
    setPaymentDetails,
    resetPaymentDetails: () => setPaymentDetails(defaultValues.paymentDetails),
  });

  useEffect(() => {
    setContextValue(contextValue => ({
      ...contextValue,
      billingDetails,
      paymentDetails,
    }));
  }, [billingDetails, paymentDetails]);

  return (
    <BillingPaymentContext.Provider value={contextValue}>
      {children}
    </BillingPaymentContext.Provider>
  );
}

function useBillingPayment() {
  const context = useContext(BillingPaymentContext);
  if (context === undefined) {
    throw new Error('usePlan must be used within a BillingPaymentContext');
  }

  return context;
}

export { BillingPaymentProvider, useBillingPayment };
