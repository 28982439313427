import React from 'react';

import AuthProvider from './providers/AuthProvider';
import { LocaleProvider } from './providers/Locale';

export default ({ element }) => (
  <LocaleProvider>
    <AuthProvider>{element}</AuthProvider>
  </LocaleProvider>
);
