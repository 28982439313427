import React from 'react';

export default function PageHeader({ children, className, withBackButton }) {
  return (
    <h2 className={className}>
      {withBackButton ? (
        !(
          window.navigator.userAgent.indexOf('MSIE ') > -1 ||
          window.navigator.userAgent.indexOf('Trident/') > -1
        ) ? (
          <svg
            className="icon-back icon-28 mr-2 mt-n2"
            title="Back Icon"
            style={{ cursor: 'pointer' }}
            onClick={() => window.history.back()}
          >
            <use xlinkHref="/assets/img/icon/icons.svg#icon-back"></use>
          </svg>
        ) : (
          <span
            className="px-1"
            style={{ cursor: 'pointer' }}
            onClick={() => window.history.back()}
          >
            &larr;
          </span>
        )
      ) : null}
      {children}
    </h2>
  );
}
