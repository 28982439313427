export const canadianLocale = {
  localeDetails: {
    isoCode: 'CA',
    exclusive: false,
    name: 'Canada',
    enabled: true,
    defaultToSubscription: true,
    defaultLocale: 'en-us',
  },
  geoLocated: false,
};

export const unitedStatesLocale = {
  localeDetails: {
    isoCode: 'US',
    exclusive: false,
    name: 'United States',
    enabled: true,
    defaultToSubscription: true,
    defaultLocale: 'en-us',
  },
  geoLocated: false,
};

export const brazilLocale = {
  localeDetails: {
    isoCode: 'BR',
    exclusive: true,
    name: 'Brazil',
    enabled: true,
    defaultToSubscription: false,
    defaultLocale: 'en-us',
  },
  geoLocated: true,
};
