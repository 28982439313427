import React, { createContext, useState, useContext, useEffect } from 'react';
import { useStateWithSessionStorage } from '@util/hooks/useStateWithSessionStorage';

const LocaleContext = createContext({});

const defaultValues = {
  locale: {
    localeDetails: {
      isoCode: 'US',
      exclusive: false,
      name: 'United States',
      enabled: true,
      defaultToSubscription: true,
      defaultLocale: 'en-us',
    },
    geoLocated: false,
  },
  currency: 'USD',
  language: 'English (US)',
};
function LocaleProvider({ children }) {
  const [locale, setLocale] = useStateWithSessionStorage(
    'locale',
    defaultValues.locale,
    true
  );
  const [selectedCurrency, setSelectedCurrency] = useStateWithSessionStorage(
    'selectedCurrency',
    defaultValues.currency,
    true
  );
  const [selectedLanguage, setSelectedLanguage] = useStateWithSessionStorage(
    'selectedLanguage',
    defaultValues.language,
    true
  );
  const [contextValue, setContextValue] = useState({
    locale,
    setLocale,
    selectedCurrency,
    setSelectedCurrency,
    selectedLanguage,
    setSelectedLanguage,
  });

  useEffect(() => {
    setContextValue(prevContext => ({
      ...prevContext,
      locale,
      selectedCurrency,
      selectedLanguage,
    }));
  }, [locale, selectedCurrency, selectedLanguage]);

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
}

function useLocale() {
  const context = useContext(LocaleContext);
  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleContext');
  }

  return context;
}

export { LocaleProvider, useLocale };
