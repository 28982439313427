import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import FlagIcon from '../../images/FlagIcon';
import { useUser } from '@util/providers/AuthProvider';
import { useLocale } from '@util/providers/Locale';
import { sortList } from '@util/functions';
import { useLicense } from '@util/providers/License';
import RegionLoadingModal from './RegionLoadingModal';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export default function RegionSelect() {
  const { t } = useTranslation();
  const { locale, setLocale, setSelectedCurrency } = useLocale();
  const { licensePlans } = useLicense();
  const { bmoUser } = useUser();
  const [dropdownRegions, setDropdownRegions] = useState();
  const [settingDropdownRegions, setSettingDropdownRegions] = useState();

  const isSubscriber =
    bmoUser &&
    bmoUser.zuoraInfo &&
    bmoUser.zuoraInfo.subscriptions &&
    bmoUser.zuoraInfo.subscriptions.length !== 0;

  useEffect(() => {
    let url =
      process.env.INTERNAL_SERVICE_URL +
      'admin/regions?ShowDisabled=false&ShowUneditable=true';
    setSettingDropdownRegions(true);
    axios(url, {
      method: 'GET',
    })
      .then(function(response) {
        if (response.status === 200) {
          return response;
        }
        throw Error(response.statusText);
      })
      .then(function(response) {
        setDropdownRegions(sortList(response.data));
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => {
        setSettingDropdownRegions(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <UncontrolledDropdown>
      {dropdownRegions && (
        <>
          <DropdownToggle
            caret
            color="outline-opaque-secondary"
            className="btn btn-block"
            disabled={
              isSubscriber ||
              (locale?.localeDetails?.exclusive && locale?.geoLocated === true)
            }
          >
            {locale ? (
              <>
                <FlagIcon
                  code={locale?.localeDetails?.isoCode.toLowerCase()}
                  className="mr-2 pr-2"
                />
                {locale?.localeDetails?.name}
              </>
            ) : (
              <>
                <FlagIcon code={'us'} className="mr-2 pr-2" />
                {t('countries.us')}
              </>
            )}
          </DropdownToggle>

          <DropdownMenu style={{ overflowY: 'scroll', maxHeight: '40rem' }}>
            {dropdownRegions.map(region => (
              <DropdownItem
                id={region.isoCode}
                key={region.isoCode}
                autoFocus={
                  region.isoCode === locale?.localeDetails?.isoCode && true
                }
                onClick={() => {
                  setSelectedCurrency(
                    region.isoCode === 'CA'
                      ? 'CAD'
                      : region.isoCode === 'US'
                      ? 'USD'
                      : licensePlans?.pricing[region.isoCode]?.currency
                  );
                  setLocale({
                    localeDetails: region,
                    geoLocated: false,
                  });
                  if (region.exclusive) {
                    navigate('/partner');
                  } else if (window.location.href.includes('partner')) {
                    if (region.defaultToSubscription === true) {
                      navigate('/');
                    } else {
                      navigate('/license');
                    }
                  }
                }}
              >
                <FlagIcon
                  code={region?.isoCode.toLowerCase()}
                  className="mr-2 pr-2"
                />
                {region.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </>
      )}
      <RegionLoadingModal isOpen={settingDropdownRegions} />
    </UncontrolledDropdown>
  );
}
