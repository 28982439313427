import React, { createContext, useState, useContext, useEffect } from 'react';

import { useStateWithSessionStorage } from '../hooks/useStateWithSessionStorage';

const InvoiceContext = createContext({});

const defaultValues = {
  invoiceNumber: null,
  invoiceId: null,
  invoicePreview: null,
};
function InvoiceProvider({ children }) {
  const [invoiceNumber, setInvoiceNumber] = useStateWithSessionStorage(
    'invoiceNumber',
    defaultValues.invoiceNumber
  );
  const [invoiceId, setInvoiceId] = useStateWithSessionStorage(
    'invoiceId',
    defaultValues.invoiceId
  );
  const [invoicePreview, setInvoicePreview] = useStateWithSessionStorage(
    'invoicePreview',
    defaultValues.invoicePreview
  );

  //   const [state, dispatch] = useReducer(reducer, {})
  const [contextValue, setContextValue] = useState({
    invoiceNumber,
    setInvoiceNumber,
    resetInvoiceNumber: () => setInvoiceNumber(defaultValues.invoiceNumber),
    invoiceId,
    setInvoiceId,
    resetInvoiceId: () => setInvoiceId(defaultValues.invoiceId),
    invoicePreview,
    setInvoicePreview,
    resetInvoicePreview: () => setInvoicePreview(defaultValues.invoicePreview),
  });

  useEffect(() => {
    setContextValue(contextValue => ({
      ...contextValue,
      invoiceNumber,
      invoiceId,
      invoicePreview,
    }));
  }, [invoiceNumber, invoiceId, invoicePreview]);

  return (
    <InvoiceContext.Provider value={contextValue}>
      {children}
    </InvoiceContext.Provider>
  );
}

function useInvoice() {
  const context = useContext(InvoiceContext);
  if (context === undefined) {
    throw new Error('usePlan must be used within a InvoiceContext');
  }

  return context;
}

export { InvoiceProvider, useInvoice };
