import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';

const data = [
  {
    id: 'xs',
    desc: 'Extra small devices (portrait phones, less than 576px)',
  },
  {
    id: 'sm',
    desc: 'Small devices (landscape phones, 576px and up)',
  },
  {
    id: 'md',
    desc: 'Medium devices (tablets, 768px and up)',
  },
  {
    id: 'lg',
    desc: 'Large devices (desktops, 992px and up)',
  },
  {
    id: 'xl',
    desc: 'Extra large devices (large desktops, 1200px and up)',
  },
  { id: 'xxl', desc: '1920px and up' },
  { id: 'xxxl', desc: '2560px and up' },
];

export default function ResponsiveHelper() {
  return (
    <div className="container">
      <div className="my-2 py-3">
        <div className="mb-2">What responsive size am I?</div>
        {data.map((size, index, array) => {
          const inactiveClassNames = classNames(
            'badge-dark',
            [`d${index === 0 ? '' : `-${size.id}`}-none`],
            {
              [`d-${array[index + 1] &&
                array[index + 1].id}-inline-block`]: array[index + 1],
            }
          );
          const activeClassNames = classNames('badge-primary', {
            'd-inline-block': index === 0,
            [`d-${array[index + 1] && array[index + 1].id}-none`]:
              index + 1 !== array.length,
            [`d-none d-${size.id}-inline-block`]: index !== 0,
          });
          return (
            <React.Fragment key={`size-${size.id}`}>
              <DebugResponsiveTag
                id={`TagOff-${size.id}`}
                className={inactiveClassNames}
              >
                {size.id}
              </DebugResponsiveTag>
              <DebugResponsiveTag
                id={`TagOn-${size.id}`}
                className={activeClassNames}
              >
                {size.id}
              </DebugResponsiveTag>
              <UncontrolledTooltip
                placement="bottom"
                target={`TagOff-${size.id}`}
              >
                {size.desc}
              </UncontrolledTooltip>
              <UncontrolledTooltip
                placement="bottom"
                target={`TagOn-${size.id}`}
              >
                {size.desc}
              </UncontrolledTooltip>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

const DebugResponsiveTag = styled.span.attrs({
  className: 'badge mx-2',
})`
  font-size: 0.75rem;
  &.badge-primary {
    transform: scale(1.25);
  }
`;
