import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import LegalLinkContent from './LegalLinkContent';

export default function TermLink({ children, url, markdown, className }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  return (
    <>
      {/* eslint-disable */}
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          setIsOpen(true);
        }}
        className={className}
      >
        {children}
      </a>
      {/* eslint-enable */}
      <Modal
        id="LegalLinkModal"
        isOpen={isOpen}
        toggle={close}
        size="xl"
        className="modal-dialog-scrollable"
      >
        <ModalHeader>
          {children}
          <button
            type="button"
            className="close"
            id="CloseLegalDocHeader"
            onClick={close}
            aria-label="Close"
          >
            <span aria-hidden="true" />
          </button>
        </ModalHeader>
        <ModalBody id="modalBody">
          <LegalLinkContent markdown={markdown} url={url} />
        </ModalBody>
        <ModalFooter className="pb-5">
          <button
            type="button"
            id="CloseLegalDocFooter"
            onClick={close}
            className="btn btn-primary"
          >
            {t('action.close')}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
