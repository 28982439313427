const serviceURLBase = process.env.SERVICE_URL;
// TODO: eventually we won't need to create bmo account explicitly so we can rename that prop in the future
export default {
  CREATE_ACCOUNT: `${process.env.SSO_URL}/api/account/signin`,
  ZUORA_INFO: `${serviceURLBase}subscriptions`,
  SUBMIT_PAYMENT: `${serviceURLBase}subscriptions/subscribe/cc`,
  SUBMIT_PAYMENT_PO: `${serviceURLBase}subscriptions/subscribe/po`,
  UPDATE_PAYMENT: `${serviceURLBase}subscriptions/paymethod/cc`,
  START_TRIAL: `${serviceURLBase}subscriptions/subscribe/trial`,
  TAX_EXEMPT: `${serviceURLBase}subscriptions/subscribe/taxexempt`,
  UPLOAD_PO: `${serviceURLBase}subscriptions/uploadpo`,
  CREATE_BMO_ACCOUNT: `${serviceURLBase}me`,
  GET_INVOICE: `${serviceURLBase}subscriptions/invoice?invoiceId=`,
  GET_PO: `${serviceURLBase}subscriptions/account/ponum`,
  CREATE_AMENDMENT: `${serviceURLBase}subscriptions/modify`,
  AUTO_RENEW: `${serviceURLBase}subscriptions/renew`,
  FUTURE_AMENDMENTS: `${serviceURLBase}subscriptions/futureamendments`,
  BM7_EDITOR_RELEASE_NOTES: `https://download.mytobiidynavox.com/Boardmaker/Release%20Notes/Boardmaker7Editor_ReleaseNotes.pdf`,
  BM7_STUDENT_CENTER_RELEASE_NOTES: `https://download.mytobiidynavox.com/Boardmaker/Release%20Notes/Boardmaker7StudentCenter_ReleaseNotes.pdf`,
  BOARDMAKER_RELEASE_NOTES: `https://download.mytobiidynavox.com/Boardmaker/Release%20Notes/TobiiDynavox_BoardmakerStudio_ReleaseNotes.pdf`,
  GET_LICENSES: `${serviceURLBase}licenses`,
  GET_LOCATION: `${serviceURLBase}subscriptions/location`,
  // DOWNLOAD_URL: `${serviceURLBase}products/${process.env.DOWNLOAD_PRODUCT_SKU}/download`,
  LICENSE_KEY: `${process.env.QLM_SERVICE_URL}licenses/orders`,
  STUDIO_REQUIREMENTS: `https://download.mytobiidynavox.com/BoardmakerStudio/BoardmakerStudioDeploymentGuide.pdf`,
  BOARDMAKER_REQUIREMENTS: `https://download.mytobiidynavox.com/Boardmaker/v7/Boardmaker7_CompatibilityGuide_en-US.pdf`,
  SCMP_REQUIREMENTS: `https://download.mytobiidynavox.com/Boardmaker/documents/BoardmakerStudentCenter_v1-8-0_SystemRequirements.pdf`,
  GET_DOWNLOADS_BY_PRODUCT: productId =>
    `${serviceURLBase}products/${productId}/download`,
  GET_VERSIONS_BY_PRODUCT: productId =>
    `${serviceURLBase}products/${productId}/versions`,
  GET_CURRENT_BY_PRODUCT: productId =>
    `${serviceURLBase}products/${productId}/current`,
};
